import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {path: '/', name: 'root', redirect: 'home'},
  {path: '/home', name: 'home', component: () => import( /* webpackChunkName: "home.page" */ './pages/home.page.vue')},
  {path: '/my-products', name: 'my-products', component: () => import( /* webpackChunkName: "my-products.page" */ './pages/my-products.page.vue')},
  {path: '/invite/:code', name: 'invite', component: () => import( /* webpackChunkName: "invite.page" */ './pages/invite.page.vue')},
  {path: '/user/profile', name: 'user-profile', component: () => import( /* webpackChunkName: "user-profile.page" */ './pages/user-profile.page.vue')},
  {
    path: '/change-email/confirm',
    name: 'change-email-confirm',
    component: () => import( /* webpackChunkName: "change-email-confirm.page" */ './pages/change-email-confirm.page.vue'),
  },

  // Questionnaires
  {
    path: '/questionnaires',
    name: 'questionnaires',
    component: () => import( /* webpackChunkName: "questionnaires.page" */ './pages/questionnaire/questionnaires.page.vue'),
  },
  {
    path: '/questionnaires/archive',
    name: 'questionnaires-archive',
    component: () => import( /* webpackChunkName: "questionnaires-archive.page" */ './pages/questionnaire/questionnaires-archive.page.vue'),
  },
  {
    path: '/questionnaires/create',
    name: 'questionnaire-create',
    component: () => import( /* webpackChunkName: "questionnaire-create.page" */ './pages/questionnaire/questionnaire-create.page.vue'),
  },
  {
    path: '/questionnaires/:id',
    name: 'questionnaire-details',
    component: () => import( /* webpackChunkName: "questionnaire-details.page" */ './pages/questionnaire/questionnaire-details.page.vue'),
  },
  {path: '/analysis', name: 'analysis', component: () => import( /* webpackChunkName: "analysis.page" */ './pages/analysis.page.vue')},
  {path: '/tickets', name: 'tickets', component: () => import( /* webpackChunkName: "tickets.page" */ './pages/ticket/tickets.page.vue')},
  {path: '/tickets/:id', name: 'ticket-details', component: () => import( /* webpackChunkName: "ticket-details.page" */ './pages/ticket/ticket-details.page.vue')},

  // 404-Page needs to be last in routes array
  {path: '*', name: '404', component: () => import( /* webpackChunkName: "404.page" */ './pages/404.page.vue')},
];

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
