import {i18n} from '@cat-europe/common';
import deepmerge from 'deepmerge';

export const en = deepmerge(i18n.en, {
  action: {
    submitSuggestion: 'Submit Suggestion',
    suggestNewResource: 'Suggest new Resource',
    createNewQuestionnaire: 'Create new Questionnaire',
    submitELearningFeedback: 'Submit Feedback for CAT eLearning',
    archiveItem: 'Archive {item}',
    createNewItemF: 'Create new {item}',
    createNewItemM: 'Create new {item}',
    resetItem: 'Reset {item}',
    startOverItem: 'Start over {item}',
    rename: 'Rename',
    reset: 'Reset',
    startOver: 'Repeat all questions',
    renameItem: 'Rename {item}',
    rotate: 'Rotate',
    restore: 'Restore',
    restoreItem: 'Restore {item}',
    startItem: 'Start {item}',
    repeatWrongQuestions: 'Repeat wrong questions',
  },
  label: {
    accountLanguage: 'Account Language',
    estimatedRemainingEffort: 'Estimated Remaining Effort',
    progress: 'Progress',
    archive: 'Archive',
    analysis: 'Analysis',
    latestItems: 'Latest {items}',
    quickAccess: 'Quick Access',
    amount: 'Amount',
    quickQuestionnaire: 'Quick Questionnaire',
    activatingProduct: 'Activating Product {product}',
  },
  text: {
    analysis: {
      questionnaireDescription: 'Click on the Bars in the Diagram to add Questions to the Questionnaire',
      noQuestionsSelected: 'No questions selected',
      noQuestionnaires: 'No questionnaires available',
    },
    activateProduct: {
      line1: 'You are activating "{productName}" for "{email}".',
      line2: '"{productName}" will become available to you until {expirationDate}.',
    },
    invalidProductActivation: {
      title: 'Invalid link',
      line1: 'The link you have followed is invalid.',
      line2: 'This Access Pass has either already been activated or it has been retracted by the Organization that issued it.',
    },
    invite: {
      register: 'I have not created a CAT Question Training Account yet and want to register.',
      login: 'I already have a CAT Question Training Account and want to log in.',
    },
  },
  dialog: {
    startOverQuestionnaire: 'Would you like to start a new instance of this questionnaire?',
    moveItemToArchive: `Would you like to move "{item}" into the archive?

This will remove all answered questions from your overall progress.
If you want return these answered questions back to your progress, you can unarchive the questionnaire again.`,
    restoreItemFromArchive: `Would you like to restore "{item}" from the archive?

This will add all answered questions to your overall progress again.`,
    repeatWrong: 'Start a new Questionnaire with all incorrect answered questions',
    accessPassActivated: {
      title: 'Access Pass activated',
      line1: 'The Access Pass for "{catalogueName}" is now active until {expirationDate}',
      line2: 'You can now create new Questionnaires using this Access Pass.',
    },
  },
  notify: {
    renameItemSuccess: '{item} has been renamed successfully.',
    resetItemSuccess: '{item} has been resetted successfully.',
    archiveItemSuccess: '{item} has been moved into the archive.',
    restoreItemSuccess: '{item} has been restored from the archive.',
    changeEmailLinkSentSuccess: 'An email has been sent to {email} to confirm the change.',
    feedbackSuccess: 'You have created a Ticket. You will be notified via email, when it is updated.',
    suggestResourceSuccess: 'Thank you for submitting a new resource! Your submission will be evaluated by a subject matter expert and you can see the status of this review in your Ticket Overview.',
  },
  warning: {
    noValidAccessPasses: 'You do not have valid AccessPasses.',
  },
  phrase: {
    dontHaveAccount: 'I don\'t have an Account',
    alreadyHaveAccount: 'I already have an Account',
    pleaseSelectAssociateAccount: 'Please select the Account with which to associate',
  },
});
