import {i18n} from '@cat-europe/common';
import deepmerge from 'deepmerge';

export const de = deepmerge(i18n.de, {
  action: {
    submitSuggestion: 'Vorschlag einreichen',
    suggestNewResource: 'Neue Resource vorschlagen',
    createNewQuestionnaire: 'Neue Befragung erstellen',
    submitELearningFeedback: 'Feedback zu CAT eLearning abgeben',
    archiveItem: '{item} archivieren',
    createNewItemF: 'Neue {item} erstellen',
    createNewItemM: 'Neues {item} erstellen',
    resetItem: '{item} zurücksetzen',
    startOverItem: '{item} neu starten',
    rename: 'Umbenennen',
    reset: 'Zurücksetzen',
    startOver: 'Alle Fragen wiederholen',
    renameItem: '{item} umbenennen',
    rotate: 'Drehen',
    restore: 'Wiederherstellen',
    restoreItem: '{item} wiederherstellen',
    startItem: '{item} starten',
    repeatWrongQuestions: 'Falsche Fragen wiederholen',
  },
  label: {
    accountLanguage: 'Account Sprache',
    estimatedRemainingEffort: 'Geschätzter Restaufwand',
    progress: 'Fortschritt',
    archive: 'Archiv',
    analysis: 'Auswertung',
    latestItems: 'Letzte {items}',
    quickAccess: 'Schnellzugriff',
    amount: 'Anzahl',
    quickQuestionnaire: 'Schnellbefragung',
    activatingProduct: 'Aktiviere Produkt "{product}"',
  },
  text: {
    analysis: {
      questionnaireDescription: 'Klicke auf die Balken im Diagramm um der Befragung Fragen hinzuzufügen',
      noQuestionsSelected: 'Keine Fragen ausgewählt',
      noQuestionnaires: 'Keine Befragungen vorhanden',
    },
    activateProduct: {
      line1: 'Das Produkt "{productName}" wird für "{email}" aktiviert.',
      line2: 'Dadurch wird "{productName}" auf diesem Account verfügbar bis {expirationDate}.',
    },
    invalidProductActivation: {
      title: 'Ungültiger Link',
      line1: 'Dieser Link ist ungültig.',
      line2: 'Der Zugangspass wurde entweder bereits aktiviert, oder er wurde von der ausstellenden Organisation zurückgezogen.',
    },
    invite: {
      register: 'Ich habe zuvor noch keinen CAT Question Training Account erstellt und möchte mich registrieren.',
      login: 'Ich habe bereits einen CAT Question Training Account und möchte mich einloggen.',
    },
  },
  dialog: {
    startOverQuestionnaire: 'Möchtest du eine neue Instanz dieser Befragung starten?',
    moveItemToArchive: `Möchtest du "{item}" in das Archiv verschieben?

Hierdurch werden alle darin beantworteten Fragen aus deinem Gesamtfortschritt entfernt.

Wenn du die beantworteten Fragen wieder deinem Fortschritt hinzufügen möchtest, kannst du die Befragung später wiederherstellen.`,
    restoreItemFromArchive: `Möchtest du "{item}" aus dem Archiv wiederherstellen?

Hierdurch werden alle darin beantworteten Fragen wieder deinem Gesamtfortschritt hinzugefügt.`,
    repeatWrong: 'Starte eine neue Befragung mit den falsch beantworteten Fragen',
    accessPassActivated: {
      title: 'Zugangspass aktiviert',
      line1: 'Der Zugangspass für "{catalogueName}" ist ab jetzt bis zum {expirationDate} aktiv',
      line2: 'Du kannst nun neue Befragungen mit diesem Zugangspass erstellen.',
    },
  },
  notify: {
    renameItemSuccess: '{item} wurde erfolgreich umbenannt.',
    resetItemSuccess: '{item} wurde erfolgreich zurückgesetzt.',
    archiveItemSuccess: '{item} wurde erfolgreich in das Archiv verschoben.',
    restoreItemSuccess: '{item} wurde erfolgreich aus dem Archiv geholt.',
    changeEmailLinkSentSuccess: 'Ihnen wurde eine Email an {email} geschickt um die Änderung zu bestätigen.',
    feedbackSuccess: 'Du hast ein Ticket erstellt. Du wirst per Email benachrichtigt, sobald es bearbeitet wird.',
    suggestResourceSuccess: 'Vielen Dank für das Einreichen einer neuen Resource! Ein Fachgebietsexperte wird Deinen Vorschlag auswerten und Du kannst den Status dieser Auswertung in Deinem Ticket Bereich verfolgen.',
  },
  warning: {
    noValidAccessPasses: 'Dir sind keine gültigen Zugangspässe für den Question-Trainer zugewiesen.',
  },
  phrase: {
    dontHaveAccount: 'Ich habe noch keinen Account',
    alreadyHaveAccount: 'Ich habe bereits einen Account',
    pleaseSelectAssociateAccount: 'Wähle bitte den Account, dem du das Produkt zuordnen möchtest',
  },
});
