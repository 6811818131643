import {Module} from 'vuex';
import {RootState} from '~/store';

export interface TrainingState {
  questionnaireIndices: { [questionnaireId: number]: number },
}

function getQuestionnaireIndices() {
  try {
    const obj = JSON.parse(window.localStorage.getItem('questionnaireIndices'));
    return obj ? obj : {};
  } catch (e) {
    return {};
  }
}

export const trainingStore: Module<TrainingState, RootState> = {
  namespaced: true,
  state: {
    questionnaireIndices: getQuestionnaireIndices(),
  },
  mutations: {
    setLatestIndex(state, data: { questionnaireId: number, index: number }) {
      state.questionnaireIndices[data.questionnaireId] = data.index;
      window.localStorage.setItem('questionnaireIndices', JSON.stringify(state.questionnaireIndices));
    },
  },
  actions: {},
  getters: {},
};
