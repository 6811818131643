import {AccessPassData} from '@cat-europe/common';
import {User} from '@cat-europe/common/web';
import {Module} from 'vuex';
import {api} from '~/plugins/api';
import {RootState} from '~/store/index';

export interface AuthState {
  isAuthPending: boolean,
  isAuthenticated: boolean,
  accessToken: string,
  accessPasses: AccessPassData[],
  refreshTimeoutId: number,
  expiresAt: number,
  user?: User,
}


export const authStore: Module<AuthState, RootState> = {
  namespaced: true,
  state: {
    isAuthPending: true,
    isAuthenticated: false,
    accessToken: null,
    accessPasses: [],
    refreshTimeoutId: null,
    expiresAt: null,
    user: null,
  },
  mutations: {
    setAccessToken(state, token) {
      state.accessToken = token;
    },

    setAccessPasses(state, accessPasses) {
      state.accessPasses = accessPasses;
    },
    setUser(state, user) {
      state.user = user;
      state.isAuthenticated = true;
      state.isAuthPending = false;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.accessToken = null;
      state.user = null;
    },
    setAuthPending(state, isPending) {
      state.isAuthPending = isPending;
    },
    setExpiresAt(state, expiresAt) {
      state.expiresAt = expiresAt;
    },
  },
  actions: {
    async register({commit, dispatch}, payload) {
      const {data} = await api.post('/api/auth/register', payload);
      commit('setAccessToken', data.token);
      commit('setUser', data.user);
      commit('setAccessPasses', data.accessPasses);
      dispatch('renewToken', data.token);
    },
    async login({commit, dispatch}, credentials) {
      const {data} = await api.post('/api/auth/login', credentials);
      commit('setAccessToken', data.token);
      commit('setUser', data.user);
      commit('setAccessPasses', data.accessPasses);
      dispatch('renewToken', data.token);
      dispatch('rating/setRatings', data.ratings, {root: true});
    },
    async loginByToken({commit, dispatch}) {
      try {
        const {data} = await api.post('/api/auth/refresh');
        commit('setAccessToken', data.token);
        commit('setUser', data.user);
        commit('setAccessPasses', data.accessPasses);
        dispatch('renewToken', data.token);
        dispatch('rating/setRatings', data.ratings, {root: true});
      } catch (err) {
        commit('setAuthPending', false);
      }
    },
    async logout(context) {
      clearTimeout(context.state.refreshTimeoutId);
      await api.get('/api/auth/logout');
      context.commit('logout');
    },
    async refreshToken({commit, dispatch}) {
      const {data} = await api.post('/api/auth/refresh');
      commit('setAccessToken', data.token);
      dispatch('renewToken', data.token);
    },
    renewToken(context, token) {
      const payloadString = token.split('.')[1];
      const payload = JSON.parse(atob(payloadString));
      const expiresAt = payload.exp * 1000;
      const expiresIn = expiresAt - Date.now();
      const secondsBeforeExpiration = 30;
      const timeout = expiresIn - (secondsBeforeExpiration * 1000);
      context.commit('setExpiresAt', expiresAt);

      if (timeout < 0) {
        console.warn('Server Time may be invalid!');
        return;
      }

      // request fresh access-token 30s before its expiration
      context.state.refreshTimeoutId = window.setTimeout(() => context.dispatch('refreshToken').catch((_e) => console.log('failed to refresh access-token')), timeout);
    },
  },
  getters: {},
};
