import {PT_User_RatingData} from '@cat-europe/common/models/pivot/PT_User_Rating.data';
import {Module} from 'vuex';
import {api} from '~/plugins/api';
import {RootState} from '~/store';

export interface RatingState {
  ratings: PT_User_RatingData[],
}

export const ratingStore: Module<RatingState, RootState> = {
  namespaced: true,
  state: {
    ratings: [],
  },
  mutations: {},
  actions: {
    setRatings({state}, ratings) {
      state.ratings = ratings;
    },
    async putRating({state}, payload) {
      const response = await api.put('/api/ratings', {
        ratableId: payload.ratableId,
        ratableType: payload.ratableType,
        like: payload.like,
      });
      const idx = state.ratings.findIndex(r => r.ratableType === payload.ratableType && r.ratableId === payload.ratableId);
      const result: any = {};
      if (idx > -1) {
        result.remove = {like: state.ratings[idx].like};
        state.ratings.splice(idx, 1);
      }
      if (response.data) {
        state.ratings.push(response.data);
        result.add = {like: payload.like};
      }
      return result;
    },
  },
  getters: {},
};
