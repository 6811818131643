import Vue from 'vue';
import Vuex from 'vuex';
import {appStore} from '~/store/app.store';
import {authStore} from '~/store/auth.store';
import {ratingStore} from '~/store/rating.store';
import {trainingStore} from '~/store/training.store';

Vue.use(Vuex);
export type RootState = any;

export const store = new Vuex.Store<RootState>({
  plugins: [],
  modules: {
    app: appStore,
    auth: authStore,
    training: trainingStore,
    rating: ratingStore,
  },
  state: {},
  mutations: {},
  actions: {},
});
